var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',[(_vm.data && _vm.data.SanctionValues)?_c('tfoms-finance',{attrs:{"title":"СУММА САНКЦИЙ","values":[
          {
            value: _vm.data.SanctionTotalValue,
            label: this.provideUnits.rub.text,
          } ],"data":_vm.getMonthValues(
            _vm.data.SanctionValues.map(function (e) { return ({
              value: [e.SanctionValue, e.FineValue],
              id: e.Id,
            }); })
          ),"labels":_vm.labels,"sub-title":_vm.subTitlePeriod}}):_vm._e()],1),_c('v-col',[_c('tfoms-progress',{attrs:{"title":"Финансовые санкции (в разрезе кодов)","icon":"mdi-currency-rub","factColor":"#FF8761","data":_vm.finances,"type":1,"value":_vm.selectCode,"backgroundColor":"#F95050","factText":"Штрафы","planText":"Не оплата","labels":_vm.labels,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.rub.text,
          } ]},on:{"click":_vm.onclick}})],1)],1),(_vm.data && _vm.data.Children)?_c('tfoms-condition-table',{staticClass:"mt-5",attrs:{"data":_vm.data.Children.map(function (e) { return ({
        plan: e.NonPaymentValue,
        fact: e.FineValue,
        percent: e.SanctionValue,
        Name: e.Name,
        id: e.Id,
        Codes: e.Codes,
      }); }),"icon":"mdi-currency-rub","iconColor":"#FF8761","labels":_vm.labelsCode,"subTitle":_vm.subTitlePeriod,"color":"green","isSancation":"","values":[
      {
        label: this.provideUnits.rub.text,
      } ]}}):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-partition-table',{attrs:{"data":_vm.data.Divisions,"labels":_vm.labelsCode,"type":2,"icon":"mdi-currency-rub","iconColor":"#FF8761","sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.rub.text,
          } ]},model:{value:(_vm.selectEmployee),callback:function ($$v) {_vm.selectEmployee=$$v},expression:"selectEmployee"}}):_vm._e()],1),_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-employee-table',{ref:"tfomsEmployeeTable",attrs:{"selectPartiton":_vm.selectEmployee,"data":_vm.data.Divisions,"labels":_vm.labelsCode,"type":2,"icon":"mdi-currency-rub","iconColor":"#FF8761","items":_vm.data.Employees,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.rub.text,
          } ]}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }